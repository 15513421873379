<template>
  <div>
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" style="height: calc(100vh - 60px)">
      <header>
        <div class="info">{{ $t('正在托管的资金') }}</div>
        <div class="money">
          <div><span class="money-num">{{ escrowBalance }}</span><span class="txt">USDT</span></div>
          <div class="desc">{{ $t('说明') }} ></div>
        </div>
        <div class="item">
          <div>
            <div>{{ $t('预计今日收益') }}</div>
            <div class="num">{{ cost_day }}</div>
          </div>
          <div>
            <div>{{ $t('累计收益') }}</div>
            <div class="num">{{ cost_total }}</div>
          </div>
          <div>
            <div>{{ $t('委托中的订单') }}</div>
            <div class="num">{{ order_num }}</div>
          </div>
        </div>
        <div><button @click="$router.push({ path: '/investMy'})">{{ $t('订单详情') }}</button></div>
      </header>
      <div class="main">
        <div class="item" v-for="(item,index) in dataList" :key="index">
          <div class="title">
            <span class="icon">
              <img src="@/assets/img/btc.png" style="width:1.6rem">
              <span>{{ item.title }}</span>
            </span>
            <span>{{ $t('可购次数') }} <span class="num">{{item.buy_use_num}}/{{item.buy_num}}</span></span>
          </div>
          <div class="row">
            <div class="col">
              <div class="subtitle">{{ $t('限额') }}</div>
              <div class="num">{{item.buy_min}} - {{ item.buy_max }}</div>
            </div>
            <div class="col">
              <div class="subtitle">{{ $t('日收益率') }}</div>
              <div class="num">{{item.rate}}%</div>
            </div>
            <div class="col">
              <div class="subtitle">{{ $t('周期') }}</div>
              <div class="num">{{ item.day }}({{ $t('天') }})</div>
            </div>
          </div>

          <div><button :disabled="item.buy_num == item.buy_use_num" @click="$router.push({ path: '/investForm' ,query:{id:item.id}})">{{ item.buy_num == item.buy_use_num ? '已售罄' : $t('去买入') }}</button></div>
        </div>

      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  components: {
    MescrollVue // 注册mescroll组件
  },
  data() {
    return {
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, // 下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        textLoading: 'loading...'
      },
      dataList: [], // 列表数据
      userInfo:[],
      escrowBalance: 0,
      cost_day:0,
      cost_total:0,
      order_num:0,
    }
  },
  created() {
    // this.getUserInfo()
    this.getEscrowBalance()
  },
  methods: {
      // 用户信息
    // async getUserInfo() {
    //   const { data } = await this.$http.get('/home/user/index')
    //   if (data) {
    //     if (data.code === 200) {
    //       this.userInfo = data.data
    //     }
    //   }
    // },
      // 用户信息
    async getEscrowBalance() {
      const { data } = await this.$http.get('/home/user/escrowBalance')
      if (data) {
        if (data.code === 200) {
          this.escrowBalance = data.data.balance
        }
      }
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 联网请求
      this.$http.post('/invest/web/invest_lists', {

        page: page.num, // 页码
        limit: page.size // 每页长度

      }).then((response) => {
        console.log(response)
        this.cost_day= response.data.data.cost_day
        this.cost_total=response.data.data.cost_total
        this.order_num=response.data.data.order_num
        // 请求的列表数据
        let arr = response.data.data.list
        // 如果是第一页需手动置空列表
        if (page.num === 1) this.dataList = []
        // 把请求到的数据添加到列表
        this.dataList = this.dataList.concat(arr)
        // 数据渲染成功后,隐藏下拉刷新的状态
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }).catch((e) => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        mescroll.endErr()
      })
    }
  }
}
</script>

<style lang="less" scoped>
/*通过fixed固定mescroll的高度*/
.mescroll {
  position: fixed;
  top: 0;
  bottom: 3.33333rem;
  height: auto;
}
header {
  height: 210px;
  background-color: #004ABF;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  display: flex;
  justify-content: left;
  font-size: 1rem;

  .info {
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 0.6rem;
  }

  .money {
    text-align: left;
    display: flex;
    justify-content: space-between;

    .desc {
      font-size: 0.6rem;
      padding: 0.3rem;
      background-color: #0153C1;
    }
  }

  .money-num {
    font-size: 1.5rem;
    margin-right: 0.3rem;
  }

  .txt {
    font-size: 1.0rem;
    font-weight: 500;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 10px 0;

    .num {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 10px
    }
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #0153C1;
    height: 40px;
    line-height: 40px;
    border: none;
    border-radius: 4px;
    width: 100%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  background-color: #F6FAFB;
  .item {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subtitle {
          color: #8C908F;
          font-size: 0.8rem;
          padding: 10px 0;
        }
      }
    }

    button {
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      background-color: #0153C1;
      height: 40px;
      line-height: 40px;
      border: none;
      border-radius: 4px;
      width: 100%;
    }
  }
}
</style>
